import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import homePageStyle from "../../styles/homepage.module.scss"
import { Link } from "gatsby"
import airFiltersdata from "../../data/air-filters"
import navItems from "../../data/navItems"
import YouTubeVideo from "../YouTubeVideo"
export default function (props) {
  return (
    <StaticQuery
      query={graphql`
        query productImages {
          pressurizer: file(relativePath: { eq: "pressurizer.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          airFilters: file(relativePath: { eq: "products-air-filters.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => {
        // console.log(data);
        return (
          <div>
            <section className={homePageStyle.display} id="homepage-display">
              <div className={homePageStyle.product} id="air-filters">
                <div className={homePageStyle.productImg}>
                  <Img fluid={data.airFilters.childImageSharp.fluid} />
                </div>
                <div className={homePageStyle.desc}>
                  <h2>Cabin Air Filters</h2>
                  <p>{airFiltersdata.content.section1.p1}</p>
                  {/* <p>Clean Air Filter® provides vapor and particulate filtrations featuring a unique, patented, 3-in-1 design.</p> */}
                  <Link
                    className="primary red"
                    to={navItems[0].to + navItems[0].children[1].to}
                  >
                    View Cabin Air Filters
                  </Link>
                </div>
              </div>
              <div className={homePageStyle.divider}></div>
              <div className={homePageStyle.product} id="pressurizers">
                <div className={homePageStyle.desc}>
                  <h2>Filtration Protection Systems</h2>
                  <p>
                    Our mobile and stationary FPS units are designed to create a
                    positive flow of clean, filtered air into your environment.
                  </p>
                  <Link
                    className="primary red"
                    to={navItems[0].to + navItems[0].children[0].to}
                  >
                    View Filtration Protection Systems
                  </Link>
                </div>
                <div className={homePageStyle.productImg}>
                  <Img fluid={data.pressurizer.childImageSharp.fluid} />
                </div>
              </div>
            </section>
            <section className={homePageStyle.airTestingService}>
              <div>
                <h2
                  style={{
                    textAlign: "center",
                  }}
                >
                  Air Testing Services
                </h2>
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    maxWidth: "32rem",
                    margin: "1rem auto",
                  }}
                >
                  CAF’s testing team has the equipment and experience to travel
                  globally to the customer site for cab integrity testing to
                  aide operator protection.
                </p>
                <Link
                  style={{ margin: "auto" }}
                  className="primary red"
                  to={navItems[0].to + navItems[0].children[2].to}
                >
                  View Air Testing Services
                </Link>

                <div className="homepage-area">
                  <YouTubeVideo />
                </div>
              </div>
            </section>
          </div>
        )
      }}
    />
  )
}
